import { useEffect, useState } from 'react';
import { REDIRECT_PARAMETER } from '../components/AuthGuard';
import { useSessionInfo } from '../hooks';
import { useRouter } from 'next/router';
import { useEmbeddingContext } from '../contexts/EmbeddingProvider';
import PageError from '../components/PageError';

const Home = () => {
  const [showEmbeddedLoginError, setShowEmbeddedLoginError] = useState(false);
  const { isSignedIn, userType, workspaceId, email, firstName, lastName } =
    useSessionInfo();

  const router = useRouter();
  const redirectPath = router.query?.[REDIRECT_PARAMETER] as string;

  const { isEmbedded } = useEmbeddingContext();

  useEffect(() => {
    if (isSignedIn) {
      if (redirectPath) {
        router.push(redirectPath);
      } else {
        if (userType === 'WorkspaceMember') {
          router.replace(`/workspace/${workspaceId}`);
        } else {
          router.replace('/environment');
        }
      }
    } else {
      if (isEmbedded) {
        setShowEmbeddedLoginError(true);
      } else {
        router.push('/login');
      }
    }
  }, [
    isSignedIn,
    userType,
    workspaceId,
    router,
    email,
    firstName,
    lastName,
    redirectPath,
    isEmbedded,
  ]);

  if (showEmbeddedLoginError) {
    return (
      <PageError
        title='Embedded app misconfiguration'
        message='Please try refreshing the page. If the issue persists, please contact your administrator for assistance.'
      />
    );
  }

  return <></>;
};

export default Home;

Home.requireAuth = true;
